import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'home2',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/tabs.vue')
  },
  {
    path: '/new',
    name: 'New Goal',
    component: () => import('../views/NewGoal.vue'),
    meta: { transition: 'fade' }
  },
  {
    path: '/goal/:id',
    name: 'My Goal',
    component: () => import('../views/MyGoal.vue'),
    meta: { transition: 'fade' }
  },
  {
    path: '/edit/:id',
    name: 'Edit Goal',
    component: () => import('../views/EditGoal.vue'),
    meta: { transition: 'fade' }
  },
  {
    path: '/fund',
    name: 'Add Fund',
    component: () => import('../views/AddGoalFund.vue')
  },
  {
    path: '/addincome',
    name: 'Add Income',
    component: () => import('../views/AddIncome.vue')
  },
  {
    path: '/addexpenses',
    name: 'Add Expenses',
    component: () => import('../views/AddExpenses.vue')
  },
  {
    path: '/load',
    name: 'Loading',
    component: () => import('../views/Loader.vue')
  },
  {
    path: '/complete',
    name: 'Complete',
    component: () => import('../views/CompletedGoal.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/AuthLogin.vue')
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/AuthSignUp.vue')
  },
  {
    path: '/key',
    name: 'Key',
    component: () => import('../views/AuthPhrases.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
