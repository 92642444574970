<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<style>
  @import './assets/custom.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css';
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
 /* @import url('https://use.fontawesome.com/releases/v5.0.13/css/all.css');*/
</style>
<script>

export default {
  name: 'App',

  data: () => ({
    
  }),
  created() {
 
  },
   mounted () {
    var externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/jstat@1.9.2/dist/jstat.min.js')
    document.head.appendChild(externalScript)
    var externalScript1 = document.createElement('script')
    externalScript1.setAttribute('src', 'https://cdn.jsdelivr.net/gh/formulajs/formulajs@2.9.3/dist/formula.min.js')
    document.head.appendChild(externalScript1)
    let externalScript2 = document.createElement('script')
    externalScript2.setAttribute('src', 'https://adfconsumer.gov.au/wp-content/plugins/digitalmavens/js/budgetdata.js')
    document.head.appendChild(externalScript2)


    let externalScript3 = document.createElement('script')
    externalScript3.setAttribute('src', 'https://kit.fontawesome.com/e2faba0cc9.js')
    externalScript3.setAttribute('crossorigin', 'anonymous')
    document.head.appendChild(externalScript3)
  

    }
}
</script>
