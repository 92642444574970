import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    goalData: [],
   // endpoint:"https://adfstaging.wpengine.com/rest-api/",
    endpoint:"https://adfconsumer.gov.au/rest-api/",
    userid: null,
    incomes:[],
    expense_saving:[],
    expense_finace:[],
    expense_household:[],
    expense_regular:[],
    expense_children:[],
    expense_discretionary:[],

    select_incomes:[],
    select_expense_saving:[],
    select_expense_finace:[],
    select_expense_household:[],
    select_expense_regular:[],
    select_expense_children:[],
    select_expense_discretionary:[],


    temp_incomes:[],
    temp_expense_saving:[],
    temp_expense_finace:[],
    temp_expense_household:[],
    temp_expense_regular:[],
    temp_expense_children:[],
    temp_expense_discretionary:[],

    goalCompleted:[],
    goalOnHold: [],
    newGoalComplete:0,
    active_tab:null
  },
  mutations: {
    getGoalData(state){
      
    },
    setGoalData (state, data) {
      state.goalData = data
      var cdata = [];
      var hdata = [];
      for(var i=0; i<data.length; i++){
        if(data[i].iscompleted == 1){
          cdata.push(data[i]);
        }
        if(data[i].isonhold == 1){
          hdata.push(data[i]);
        }
      }
      state.goalCompleted = cdata
      state.goalOnHold = hdata
    },
    setNewCompleteGoal(state, data){
      state.newGoalComplete = data
    },

    setActiveTab(state, data){
      state.active_tab = data
    },

    removeGoal (state, data) {
      state.goalData.push(data)
    },

    setBudgetData(state, data){
      state.incomes = data['data_income'];
      state.expense_saving = data['data_expense_saving'];
      state.expense_regular = data['data_expense_regular'];
      state.expense_household = data['data_expense_household'];
      state.expense_finace = data['data_expense_finace'];
      state.expense_discretionary = data['data_expense_discretionary'];
      state.expense_children = data['data_expense_children'];
    },

    setExpenseData(state, data){
      for(var i=0; i<data.length; i++){
        if(data[i].type == "finace"){
          state.select_expense_finace = JSON.parse(data[i].json); 
          state.temp_expense_finace = JSON.parse(data[i].json); 
        }

        if(data[i].type == "saving"){
          state.select_expense_saving = JSON.parse(data[i].json); 
          state.temp_expense_saving = JSON.parse(data[i].json); 
        }

        if(data[i].type == "regular"){
          state.select_expense_regular = JSON.parse(data[i].json); 
          state.temp_expense_regular = JSON.parse(data[i].json); 
        }

        if(data[i].type == "household"){
          state.select_expense_household = JSON.parse(data[i].json); 
          state.temp_expense_household = JSON.parse(data[i].json); 
        }

        if(data[i].type == "discretionary"){
          state.select_expense_discretionary = JSON.parse(data[i].json); 
          state.temp_expense_discretionary = JSON.parse(data[i].json); 
        }

        if(data[i].type == "children"){
          state.select_expense_children = JSON.parse(data[i].json); 
          state.temp_expense_children = JSON.parse(data[i].json); 
        }

        if(data[i].type == "income"){
          state.select_incomes = JSON.parse(data[i].json); 
          state.temp_incomes = JSON.parse(data[i].json); 
        }

      }
    },
    
    addIncomedata(state, data){
      state.temp_incomes.unshift(data)
    },

    saveIncomedata(state, data){
      state.select_incomes = JSON.parse(JSON.stringify(state.temp_incomes));
    },

    discardIncomedata(state, data){
      state.temp_incomes = JSON.parse(JSON.stringify(state.select_incomes));
    },


    addExpenseSaving(state, data){
      state.temp_expense_saving.unshift(data)
    },

    addExpenseFinace(state, data){
      state.temp_expense_finace.unshift(data)
    },

    addExpenseHousehold(state, data){
      state.temp_expense_household.unshift(data)
    },

    addExpenseRegular(state, data){
      state.temp_expense_regular.unshift(data)
    },

    addExpenseChildren(state, data){
      state.temp_expense_children.unshift(data)
    },

    addExpenseDiscretionary(state, data){
      state.temp_expense_discretionary.unshift(data)
    },


    saveExpense(state, data){
      state.select_expense_saving = JSON.parse(JSON.stringify(state.temp_expense_saving));
      state.select_expense_finace = JSON.parse(JSON.stringify(state.temp_expense_finace));
      state.select_expense_household = JSON.parse(JSON.stringify(state.temp_expense_household));
      state.select_expense_regular = JSON.parse(JSON.stringify(state.temp_expense_regular));
      state.select_expense_children = JSON.parse(JSON.stringify(state.temp_expense_children));
      state.select_expense_discretionary = JSON.parse(JSON.stringify(state.temp_expense_discretionary));
    },

    discardExpense(state, data){
      state.temp_expense_saving = JSON.parse(JSON.stringify(state.select_expense_saving));
      state.temp_expense_finace = JSON.parse(JSON.stringify(state.select_expense_finace));
      state.temp_expense_household = JSON.parse(JSON.stringify(state.select_expense_household));
      state.temp_expense_regular = JSON.parse(JSON.stringify(state.select_expense_regular));
      state.temp_expense_children = JSON.parse(JSON.stringify(state.select_expense_children));
      state.temp_expense_discretionary = JSON.parse(JSON.stringify(state.select_expense_discretionary));
    },

    resetLocalBudget(state,data){
      state.select_incomes = [];
      state.select_expense_saving = [];
      state.select_expense_finace =  [];
      state.select_expense_household =  [];
      state.select_expense_regular =  [];
      state.select_expense_children = [];
      state.select_expense_discretionary = [];
      state.temp_incomes = [];
      state.temp_expense_saving = [];
      state.temp_expense_finace = [];
      state.temp_expense_household = [];
      state.temp_expense_regular =[];
      state.temp_expense_children = [];
      state.temp_expense_discretionary = [];
    },

    

    setUserID (state, id){
      state.userid = id;
    }
  },
  actions: {
  },
  modules: {
  }
})
